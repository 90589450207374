<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center w-100 mb-1">
        <h3>المحاضر الشاغرة</h3>
        <div>
          <b-button variant="primary" @click="onAdd()">
            <feather-icon icon="userIcon" /> إضافة المحضر
          </b-button>
        </div>
      </div>
      <div class="border pt-1 px-1 rounded-lg mb-1 shadow-sm">
        <b-row>
          <b-col cols="3">
            <form-select
              label=""
              placeholder=" ابحث هنا عن نوع المحضر..."
              class="w-50"
              :reduce="(el) => el.value"
              v-model="lecturer_type"
              :options="optionsState"
            />
          </b-col>

          <b-col cols="3">
            <form-select
              :reduce="(el) => el.value"
              label=""
              v-model="lecturer_status"
              placeholder=" ابحث هنا عن الحالة المحضر..."
              class="w-50"
              :options="optionsType"
            />
          </b-col>
          <b-col cols="6" class="d-flex justify-content-end">
          <div class="d-flex justify-content-end">
            <b-button
              v-for="(item, i) in filterDate"
              :key="i"
              size="md"
              variant="outline-success"
              pill
              class="mr-50 ml-50 h-75"
              @click="
                utils.status = item.status;
                $refs.estatesTable.refreshTable();
              "
              :class="utils.status == item.status ? 'bg-light-success' : ''"
              >{{ item.label }}</b-button
            >
          </div>
        </b-col>
        </b-row>
      </div>
      <data-table
        ref="estatesTable"
        :fields="fields"
        :utils.sync="utils"
        ep="lecturers"
      >
        <template #cell(actions)="row">
          <b-button
            variant="flat-info"
            title="عرض المزيد من المعلومات"
            size="sm"
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            class=""
            v-b-tooltip.hover.v-info.top
            @click="
              $router.push({
                name: 'lecturers-info',
                params: { id: row.item.id },
              })"
          >
            المزيد
          </b-button>
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="onUpdateClicked(row.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">تعديل</span>
            </b-dropdown-item>

            <b-dropdown-item @click="onDelete(row.item)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">حذف</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(lecturer_type)="row">
          <b-badge
            style="width: 60px"
            :variant="statusLecturers(row.item.lecturer_type).color"
            >{{ statusLecturers(row.item.lecturer_type).text }}</b-badge
          >
        </template>
        <template #cell(status)="row">
          <b-badge
            style="width: 60px"
            :variant="typeLecturers(row.item.status).color"
            >{{ typeLecturers(row.item.status).text }}</b-badge
          >
        </template>

        <template #cell(price)="row">
          <span>{{ row.item.price.toLocaleString("en-US") }}</span>
        </template>
        <template #cell(space)="row">
          <span>{{ row.item.space.toLocaleString("en-US") }}</span>
        </template>
      </data-table>
    </b-card>
    <form-modal
      ref="estateModalLecturers"
      :formSchema="estatesFormSchema"
      title="إضافة"
      v-model="activModel"
      :loading="loading"
      @confirm="onModalConfirmed"
    >
      <template #long>
        <div>
          <b-row>
            <b-col cols="6">
              <form-input
                label="خط العرض"
                v-model="markerPos.lat"
                rules="required"
              />
            </b-col>
            <b-col cols="6">
              <form-input
                label="خط الطول"
                v-model="markerPos.lng"
                rules="required"
              />
            </b-col>

            <b-col cols="12"
              ><b-button
                class="w-100 outline"
                variant="outline-primary"
                @click="mapModal = true"
              >
                <feather-icon icon="MapIcon" /> </b-button
            ></b-col>
          </b-row>

          <b-modal
            v-model="mapModal"
            centered
            size="lg"
            title=""
            footer-class="removed"
            class="edit-modal"
          >
            <select-map @markerUpdated="onLocationSelect" :lat="markerPos.lat" :lng="markerPos.lng"> </select-map>
            <LoaderBtn variant="primary" @click="mapModal = false"></LoaderBtn>
          </b-modal>
        </div>
      </template>
      <template #images>
        <form-file
          label="صورة "
          accept="image/*"
          v-model="imageSlide"
          id="form-file"
          name="image"
          placeholder="لم تقم باختيار صور"
          multiple
          @change="previewImage"
        />
        <div class="text-center mb-1">      
          <layout-input-image
            v-for="(item, i) in staticImages"
            :key="i"
            :url="item.url"
            :name="item.name"
            :is_last_imge="false"
            @deleteItem="deleteImage(item)"
          >
          </layout-input-image>
          <layout-input-image
            v-for="(item, i) in deleted_last_images"
            :key="i"
            :url="item.url"
            :is_last_imge="true"
            @deleteItem="deleteLastImage(item)"
          >
          </layout-input-image>   
        </div>
      </template>

      <template #video>
        <form-file
          label="فيديو"
          accept="video/mp4"
          id="form-video"
          name="image"
          v-model="video"
          placeholder="لم تقم باختيار فيديو"
        />
        <div v-if="video == null" class="border rounded-lg text-center mb-1">
          <feather-icon icon="VideoIcon" class="my-3" size="30" />
        </div>
        <div v-else class="border rounded-lg py-1">
          <b-row align-v="center">
            <b-col cols="10">
              <div class="mx-1">{{ video.name }}</div>
            </b-col>
            <b-col cols="2">
              <icon-media icon="VideoIcon"></icon-media>
            </b-col>
          </b-row>
        </div>
      </template>
    </form-modal>
  </div>
</template>
<script>
import {
  BCard,
  BModal,
  BButton,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BCollapse,
  VBTooltip,
} from "bootstrap-vue";
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import DataTable from "@/components/data-table/index";
import FormInput from "@/components/form-input/index";
import Ripple from "vue-ripple-directive";
import FormModal from "@/components/FormModal.vue";
import FormFile from "@/components/form-file/index.vue";
import { mapActions, mapGetters } from "vuex";
import { BCarousel, BCarouselSlide, BBadge, BImg } from "bootstrap-vue";
import IconMedia from "@/components/icon-media.vue";
import Map from "@/components/map.vue";
import manageAppService from "../../services.js";
import FormSelect from "@/components/form-select/index.vue";
import LeafletMarkerCirclePolygon from "../interactive-map/LeafletMarkerCirclePolygon.vue";
import SelectMap from "@/components/select-map/index.vue";
import LoaderBtn from "@/components/loader-btn.vue";
import LayoutInputImage from "@/components/LayoutInputImage.vue";
import skMixins from "./mixins"

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    LeafletMarkerCirclePolygon,LayoutInputImage,
    BCard,
    BModal,
    BButton,
    BCarousel,
    BCarouselSlide,
    FormFile,
    FormModal,
    Ripple,
    FormInput,
    DataTable,
    BRow,
    BCol,
    BBadge,
    BDropdown,
    BDropdownItem,
    BCollapse,
    VBTooltip,
    IconMedia,
    Map,
    BImg,
    FormSelect,
    LMap,
    LTileLayer,
    LMarker,
    SelectMap,
    LoaderBtn,
  },
  
  mixins:[skMixins],
  computed: {
    ...mapGetters("manageApp/lecturers", ["loading"]),
  },

  methods: {
    onLocationSelect(data) {
      this.markerPos.lng = data.lng;
      this.markerPos.lat = data.lat;
    },
    updateMarker(x) {
      this.markerPos = x;
    },
    deleteImage(item) {
      this.imageSlide = this.imageSlide.filter(
        (file) => file.name !== item.name
      );
      this.staticImages = this.staticImages.filter(
        (file) => file.name !== item.name
      );
    },
    deleteLastImage(item) {
      this.deleted_images.push(item.id);
      this.deleted_last_images = this.deleted_last_images.filter(
        (data) => data.id != item.id
      );
    },
    ...mapActions("manageApp/lecturers", [
      "updateLecturers",
      "createLecturers",
      "deleteLecturers",
    ]),
    statusLecturers(val) {
      return manageAppService.statusLecturers(val);
    },

    typeLecturers(val) {
      return manageAppService.typeLecturers(val);
    },

    onAdd() {
      this.$refs.estateModalLecturers.init({});
      this.activModel = true;
      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];
      this.deleted_last_images = [];
      this.video=null;
      this.markerPos.lat = "36.19849488598441";
      this.markerPos.lng = "37.16195747913507";
    },
    previewImage(e) {
      this.staticImages = Array.from(e.target.files).map((file) => ({
        url: URL.createObjectURL(file),
        name: file.name,
      }));
    },
    onUpdateClicked(form) {
      var toUpdate = {
        id: form.id,
        address: form.address,
        description: form.description,
        lecturer_type: form.lecturer_type,
        space: form.space,
        number_floors: form.number_floors,
        cladding_type: form.cladding_type,
        price: form.price,
        phone: form.phone,
        status: form.status,
        publication_date: form.publication_date,
        end_publication_date:form.end_publication_date
      };
      this.markerPos.lng = form.longitude;
      this.markerPos.lat = form.latitude;
      this.deleted_last_images = form.images;
      this.$refs.estateModalLecturers.init(toUpdate);
      this.activModel = true;

      this.imageSlide = [];
      this.staticImages = [];
      this.deleted_images = [];      
      this.video=null;
    },

    onDelete(item) {
      this.$bvModal
        .msgBoxConfirm("هل أنت متأكد من حذف المحضر", {
          title: "تأكيد حذف المحضر",
          size: "sm",
          okVariant: "danger",
          okTitle: "تأكيد",
          cancelTitle: "إلغاء",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value)
            this.deleteLecturers({ id: item.id }).then(() => {
              this.$refs.estatesTable.refreshTable();
            });
        });
    },

    onModalConfirmed(form) {
      var fd = new FormData();
      for (const key in form) {
        if (Object.hasOwnProperty.call(form, key)) {
          const element = form[key];
          if (key == "space" || key == "price" || key == "phone")
            fd.append(key, element.replace(/\D/g, ""));
          else fd.append(key, element);
        }
      }
      fd.append("longitude", this.markerPos.lng);
      fd.append("latitude", this.markerPos.lat);
      if (this.imageSlide.length !== 0)
        for (let i = 0; i <= this.imageSlide.length; i++) {
          fd.append("images[]", this.imageSlide[i]);
        }
      if (this.deleted_images.length !== 0)
        for (let i = 0; i < this.deleted_images.length; i++) {
          fd.append("deleted_images[]", this.deleted_images[i]);
        }
      if (this.video) fd.append("video", this.video);
      if (form.id) {
        fd.append("_method", "PUT");
        this.updateLecturers({ id: form.id, fd: fd }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
      } else
        this.createLecturers({ fd: fd }).then(() => {
          this.$refs.estatesTable.refreshTable();
          this.activModel = false;
        });
    },
  },

  watch: {
    lecturer_type(val) {
      this.utils.lecturer_type = val;
      this.$refs.estatesTable.refreshTable();
    },
    status(val) {
      this.utils.status = val;
      this.$refs.estatesTable.refreshTable();
    },
    lecturer_status(val){
      this.utils.lecturer_status = val;
      this.$refs.estatesTable.refreshTable();
    }
  },
};
</script>

